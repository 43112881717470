import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/pc/index'),
  },
  {
    path: '/MyProfile',
    name: 'MyProfile',
    component: () => import('@/views/pc/MyProfile'),
  },
  {
    path: '/PointShop',
    name: 'PointShop',
    component: () => import('@/views/pc/PointShop'),
  },
  {
    path: '/InvitationCenter',
    name: 'InvitationCenter',
    component: () => import('@/views/pc/InvitationCenter'),
  },
  {
    path: '/Announcement',
    name: 'Announcement',
    component: () => import('@/views/pc/Announcement'),
  },
  {
    path: '/passportList',
    name: 'passportList',
    component: () => import('@/views/pc/passportList'),
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: () => import('@/views/pc/goodsDetail'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/home/about'),
  },
  {
    path: '/hot',
    name: 'hot',
    component: () => import('@/views/home/hot'),
  },
  {
    path: '/product-detail',
    name: 'hot',
    component: () => import('@/views/home/product-detail'),
  },
  {
    path: '/strategy',
    name: 'strategy',
    component: () => import('@/views/home/strategy'),
  },
  {
    path: '/strategy-detail',
    name: 'strategy',
    component: () => import('@/views/home/strategy-detail'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/home/contact'),
  },
  {
    path: '/pcindex',
    name: 'pcindex',
    component: () => import('@/views/pc/index'),
  },
  {
    path: '/pcabout',
    name: 'pcabout',
    component: () => import('@/views/pc/pcabout'),
  },
  {
    path: '/pchot',
    name: 'pchot',
    component: () => import('@/views/pc/pchot'),
  },
  {
    path: '/hot-detail',
    name: 'pchotdetail',
    component: () => import('@/views/pc/hot-detail'),
  },
  {
    path: '/pcstrategy',
    name: 'pcstrategy',
    component: () => import('@/views/pc/pcstrategy'),
  },
  {
    path: '/pcstrategy-detail',
    name: 'pcstrategy',
    component: () => import('@/views/pc/pcstrategy-detail'),
  },
  {
    path: '/pccontact',
    name: 'pccontact',
    component: () => import('@/views/pc/pccontact'),
  },
]

const router = new VueRouter({
  routes,
})
router.afterEach(() => {
  window.scrollTo(0, 0)
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

export default router
