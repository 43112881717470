<template>
  <router-view></router-view>
</template>
<script>
import { getAll } from '@/api/global'
// import { getQueryParam } from './utils'
export default {
  created() {
    this.getcfg()
  },
  mounted() {
    // if (!window.sessionStorage.getItem('client')) {
    //   if (this.isMobile() == 'mobile') {
    //     this.$router.replace('/')
    //   } else {
    //     let inviteAddress = getQueryParam('inviteAddress')
    //     if (inviteAddress) {
    //       this.$router.replace('/?address=' + inviteAddress)
    //     } else {
    //       this.$router.replace('/')
    //     }
    //   }
    // }
    // window.sessionStorage.setItem('client', this.isMobile())
  },
  methods: {
    getcfg() {
      getAll().then((response) => {
        sessionStorage.setItem('baseConfig', JSON.stringify(response.data))
      })
    },
    isMobile() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/android/i.test(userAgent)) {
        return 'mobile'
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'mobile'
      }
      return 'pc'
    },
  },
}
</script>

<style lang="less">
.el-button--primary,
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #948060 !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--font-icon-t3);
  background: transparent;
  background-color: #353b45;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: var(--font-icon-t3);
}

::-webkit-scrollbar-track {
  background: var(--el-color-white);
}
</style>
