import request from '@/utils/request'

export const picDomain = 'https://55th.us/'
// export const picDomain = 'http://47.96.230.8:11077/'
export const passportList = [
  {
    name: 'General',
    img: 'https://55th.s3.us-east-2.amazonaws.com/passport/General.png',
    marketPrice: '1799',
    invitePrice: '1599',
    amount: '300',
    stock: '300',

  },
  {
    name: 'Prince',
    img: 'https://55th.s3.us-east-2.amazonaws.com/passport/Prince.png',
    marketPrice: '1999',
    invitePrice: '1799',
    amount: '1000',
    stock: '1000',

  },
  {
    name: 'Count',
    img: 'https://55th.s3.us-east-2.amazonaws.com/passport/Count.png',
    marketPrice: '2199',
    invitePrice: '1999',
    amount: '10000',
    stock: '10000',

  },
  {
    name: 'Knight',
    img: 'https://55th.s3.us-east-2.amazonaws.com/passport/Knight.png',
    marketPrice: '2499',
    invitePrice: '2299',
    amount: '30000',
    stock: '30000',

  },
  {
    name: 'Citizen',
    img: 'https://55th.s3.us-east-2.amazonaws.com/passport/Citizen.png',
    marketPrice: '2999',
    invitePrice: '2799',
    amount: '150000',
    stock: '150000',

  },
]

export function getSystemParam(query) {
  return request({
    url: '/api/v1/system/system',
    method: 'get',
    params: query,
  })
}
// articleItem ->titleContent or titleContentEn 搜索内容
export function getArticleList(param) {
  return request({
    url: `/public/articleItems/getArticleItemList?${param}`,
    method: 'get',
  })
}

export function getArticleDetail(param) {
  return request({
    url: `/public/articleItems/findArticleItem?ID=${param}`,
    method: 'get',
  })
}

export function createMessage(data) {
  return request({
    url: '/public/contact/createContact',
    method: 'post',
    data,
  })
}

export function getBannerList(param) {
  return request({
    url: `/public/banner/getBannerList?${param}`,
    method: 'get',
  })
}
export function getCountryList(params) {
  return request({
    url: '/public/country/getCountryList',
    method: 'get',
    params,
  })
}

export function getContinentList(params) {
  return request({
    url: 'public/continent/getContinentList ',
    method: 'get',
    params,
  })
}
// -----------------------------
export function getBanner(data) {
  return request({
    url: '/api/banner/page',
    method: 'post',
    data,
  })
}
export function getPerks(data) {
  return request({
    url: '/api/perks/page',
    method: 'post',
    data,
  })
}
export function bindEmail(data) {
  return request({
    url: '/api/bindEmail',
    method: 'post',
    data,
  })
}
export function getAll(data) {
  return request({
    url: '/api/agreement/getAll',
    method: 'get',
    data,
  })
}
export function logOut(data) {
  return request({
    url: '/api/logout',
    method: 'get',
    data,
  })
}
export function realname(data) {
  return request({
    url: '/api/user/realname',
    method: 'post',
    data,
  })
}
export function userInfo(data) {
  return request({
    url: '/api/user/info',
    method: 'get',
    data,
  })
}
export function goodsList(data) {
  return request({
    url: '/api/good/list',
    method: 'get',
    data,
  })
}
export function goodsDetail(params) {
  return request({
    url: '/api/good/detail',
    method: 'get',
    params,
  })
}

export function getNftByAddress(data) {
  return request({
    url: '/api/chain/getNftByAddress',
    method: 'post',
    data,
  })
}

export function transferRecordAdd(data) {
  return request({
    url: '/api/transferRecord/add',
    method: 'post',
    data,
  })
}

export function myInviterCounts(data) {
  return request({
    url: '/api/user/myInviterCounts',
    method: 'get',
    data,
  })
}

export function myInviter(data) {
  return request({
    url: '/api/user/myInviter',
    method: 'get',
    data,
  })
}

export function getScoreAndInvite(params) {
  return request({
    url: '/api/user/getScoreAndInvite',
    method: 'get',
    params,
  })
}

export function bindInviteCode(params) {
  return request({
    url: '/api/user/bindInviteCode',
    method: 'get',
    params,
  })
}
export function inviteClick(params) {
  return request({
    url: 'api/inviteClick',
    method: 'get',
    params,
  })
}