// 测试环境
// let baseUrl = 'http://47.96.230.8:11077';
let baseUrl = 'https://55th.us';


// 生产环境
// let baseUrl = ''

export default {
    baseUrl
};